import React, { useState } from "react";
import { ReactComponent as LogoGw } from "../../logo-GW-white.svg"
import { Box,Modal } from "@mui/material";
import { modalInfosStyle } from "../../molecules/StyleMolecule";
import { useNavigate } from 'react-router-dom';
export default function GetInfosModalAtom(props) {
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({email: ""})
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (event) =>  setFormData(event.target.value);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.email === "") {
       alert('field required')
    } else {
      const randomID = Math.floor(Math.random() * 11357)
      const randomITEM = "item" + Math.floor(Math.random() * 11357)
      navigate('/simulator/' + randomID, { state: {randomID, randomITEM} })
      setFormData({ email: "" });
    }
  };

  return (
    <div> { props.simulation.totalPaid ?
          <button className="hover:bg-white hover:text-secondary-background bg-secondary-background font-bold flex justify-center text-white rounded-2xl p-1" onClick={handleOpen}>+ D'INFOS</button> : ""}
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                 <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off" sx={modalInfosStyle}>
                      <div className="pt-4"><LogoGw  height={30} /></div>
                      <p className="font-bold p-4 text-secondary-background mb-2" name="email" id="modal-modal-title" variant="h6"
                                  component="h2">Remplissez le formulaire pour accéder au <span className="white">simulateur complet </span>!</p>
                      <input type="email" required value={formData.email} onChange={handleChange} className='text-primary-background rounded-xl mb-2 bg-white' placeholder="Adresse Mail"  />
                      <button type="submit" className="bg-secondary-background rounded p-2 font-bold"  >Accéder au simulateur Complet</button>
                 </Box>
        
          </Modal>
    </div>
  );
}
