import EuroTwoToneIcon from "@mui/icons-material/EuroTwoTone";
import { TextField } from "@mui/material";
import { FeesFieldAtomStyle } from "../../molecules/StyleMolecule";
export default function FeesFieldAtom(props) {
    return (
          <div className="content-center pr-4 justify-center items-center text-primary-background">
                    <label className="text-xl pr-10 font-bold" htmlFor="frais">Frais Pros</label>
                    <TextField type="number" name="frais" className="bg-white font-bold rounded text-primary-background " onChange={props.handleChange}
                               id="frais" variant="standard" value={props.frais} sx={FeesFieldAtomStyle}/>
                    <EuroTwoToneIcon className="text-primary-background" />
                    <div className="flex  pb-5">
                                 <p className="pt-1 pr-2"><button name="frais" value={100} type="button" onClick={props.handleChange} className="hover:bg-secondary-background bg-primary-background text-white font-bold rounded-3xl p-1 ">100€</button></p> 
                                 <p className="pt-1 pr-2"><button name="frais" value={300} type="button" onClick={props.handleChange} className="hover:bg-secondary-background bg-primary-background text-white font-bold rounded-3xl p-1 ">300€</button></p>
                                 <p className="pt-1"><button name="frais" value={1000} type="button" onClick={props.handleChange} className="hover:bg-secondary-background bg-primary-background text-white font-bold rounded-3xl p-1 ">1000€</button></p> 
                    </div>
          </div>
    )
}