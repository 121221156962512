import React, { useState } from "react";
import { Box, TextField,Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateSimulation } from "../../store/actions/SimulationActions"
import { SaveModalStyle, saveSimulationTextField} from "../../molecules/StyleMolecule";
// import AlertsAtom from "../AlertsAtom";
export default function SaveModalAtom() {
  const dispatch = useDispatch();
  // const resultat = useSelector((state) => state.save.data.results)
  const simulation = useSelector((state) => state.simulation.data)
  const [formDataModal, setFormDataModal] = useState({ name: "",email: "",tjm: Number(simulation.tjm), frais: Number(simulation.frais),
                                            tps: Number(simulation.tps), per: simulation.perAmount, pee: simulation.peeAmount,
                                            ce: simulation.ceAmount,simulationID: localStorage.getItem("simulationID")});
  const handleChange = (event) => {setFormDataModal({ ...formDataModal,[event.target.name]: event.target.value })};
  const handleSubmitModalForm = (event) => {
    event.preventDefault();
    dispatch(updateSimulation(formDataModal))
    setFormDataModal({ name: "", email: "", simulationID: localStorage.getItem("simulationID") })
    handleClose()
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>  
      {/* <AlertsAtom resultat={resultat} /> */}
      { simulation.totalPaid ?
      <button className="hover:bg-primary-background hover:text-white save bg-white border-2 border-primary-background w-72 mt-5 rounded p-2 mb-2 text-primary-background font-bold "
              onClick={handleOpen}>Enregistrer Ma Simulation</button> : ""}
      <Modal sx={SaveModalStyle} open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
             aria-describedby="modal-modal-description">
        <Box component="form">
          <div className="col">
            <TextField name="name" type="text" onChange={handleChange} id="name" label="Jean Dupont" className="inputModal"
              variant="standard" value={formDataModal.name}
              sx={saveSimulationTextField} />
            <TextField name="email" type="email" onChange={handleChange} value={formDataModal.email} required id="email"
              label="email@email.fr"  className="inputModal"  variant="standard"
              sx={saveSimulationTextField}/>
            <button className="mb-2 mt-2 font-bold"
              onClick={handleSubmitModalForm}>Enregistrer</button>
          </div>
        </Box>
      </Modal>
    </div>
   
  );
}
