import { Alert } from "@mui/material"
import CircularProgress from '@mui/material/CircularProgress';
import { AlertStyle } from "../molecules/StyleMolecule";
export default function AlertsAtom(props) {
    return (
        <div>
                <div className="fixed-top justify-center">
                            { props.resultat === "success" ? <Alert  sx={AlertStyle} severity='success'>Mail envoyé, veuillez vérifier vos mails</Alert> : ""}
                            { props.resultat === "error" ? <Alert  sx={AlertStyle} severity='error'>Vous devez au moins renseigner votre adresse mail !</Alert>  : ""}
                            { props.resultat === "" ? <Alert  sx={AlertStyle} severity='info'>NODEMAILER NOT WORKING</Alert> : ""}
                            { props.isLoading && !props.simulation.netAvImpots ? <CircularProgress color="success" /> : ""}
                </div>
                {props.isEmpty === true ? 
                         <Alert className='mt-2 mb-3 m-20' severity="error">Veuillez Renseigner les champs requis
                         </Alert>
                :  ""}
        </div>
    )
}