import { useState  } from 'react';
import Nav from '../components/layoutComponents/Nav';
// import Footer from '../components/layoutComponents/Footer';

const MainLayout = ({ children }) => {
      const [title] = useState('Calculator')
      const [navlink] = useState(['Test'])
      return (<div className="App"><Nav title={title} link={navlink} />{  children  }</div>)
}

export default MainLayout;