import React from "react";
import { Paper,Box,Typography,Modal,Table,TableContainer,TableHead,TableRow,TableBody, TableCell } from "@mui/material/";
import { ApercuModalStyle } from "../../molecules/StyleMolecule";
function createData(data, values,color) {
  return { data, values,color };
}

export default function ApercuModal(props) { 
  const rows = [
    createData("Tjm Consultant ", `${props.simulation.tjm}€`,'#52af77' ), createData("Taux de gestion GW ", `${props.simulation.tgest}%`,'red'),
    createData("Nb de jours facturés", `${props.simulation.tps} jours`,'blue'), createData("Frais de gestion GW", `${props.simulation.periodPercent}€`,'red'),
    createData("Solde du consultant", `${props.simulation.solde}€`,'#52af77'), createData("Compte de reserve brut*", `${props.simulation.reserve}€`,'#52af77'),
    createData("Frais du consultant", `${props.simulation.frais}€`,'red'),createData("Charges patronales", `${props.simulation.totalEmployerCharges}€`,'red'),
    createData("Charges salariales", `${props.simulation.totalEmployeeCharges}€`,'red'),createData("SALAIRE NET", `${props.simulation.netPayBeforeIT}€`,'#52af77'),
    createData("Plan épargne entreprise", `${props.simulation.peeAmount}€`,'#52af77'),createData("Plan épargne retraite", `${props.simulation.perAmount}€`,'#52af77'),
    createData("Comité d'entreprise", `${props.simulation.ceAmount}€`,'#52af77'),createData("Net Versé", `${props.simulation.totalPaid}€`,'green')];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
          { props.simulation.netPayBeforeIT ?
          <div>
          <button className="rounded p-2 w-72 mb-2  bg-white text-primary-background hover:bg-primary-background hover:text-white  border-2 border-primary-background font-bold" onClick={handleOpen}>Aperçu détaillé</button>
          <Modal sx={ApercuModalStyle.modal} open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
                 aria-describedby="modal-modal-description">
                 <Box>
                      <Typography id="modal-modal-title" variant="h6" sx={ApercuModalStyle.modalTitle}
                                  component="h2">Aperçu détaillé des résultats de la simulation</Typography>
                                  <div className="align">
                                     <TableContainer component={Paper} sx={ApercuModalStyle.maxHeight}>
                                         <Table stickyHeader>
                                              <TableHead>
                                                   <TableRow>
                                                          <TableCell>Données</TableCell>
                                                          <TableCell sx={ApercuModalStyle.center} >Valeurs</TableCell>
                                                   </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                    {rows.map((row) => (
                                                      <TableRow key={row.data}>
                                                        <TableCell component="th" scope="row">{row.data}</TableCell>
                                                        <TableCell sx={{color:row.color}} align="center">{row.values}</TableCell>
                                                        </TableRow> ))}
                                              </TableBody>
                                          </Table>
                                     </TableContainer> 
                                  </div>
                    </Box>
            </Modal>
            </div> : "" }
      </div>
  );
}
