// IMPORTS ACTIONS
import * as Actions from '../actions/ActionTypes';

// SELECTOR
const initialState = {
    data: [],
};


// REDUCERS
export function SimulationReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
        case Actions.GET_SIMULATION_DATA:
            return { data: action.payload }
    }
}