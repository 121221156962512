import { CardContent } from '@mui/material'
import ConstructionIcon from '@mui/icons-material/Construction';
export default function ResultParametersMolecule(props) {
    return (
        <CardContent>
                <p className='bg-white rounded-3xl flex justify-center  text-primary-background font-bold border-solid border-2 border-white'>Paramètres<ConstructionIcon fontSize='2' /></p>
                <h3>
                        <p className='mt-2 p-1 text-lg text-white font-bold'>Taux journalier moyen : <span className='text-secondary-background'>{props.simulation.tjm + "€"}</span></p>
                        <p className='p-1 text-lg text-white font-bold'>Frais profesionnels : <span className='text-secondary-background'>{props.simulation.frais + "€"}</span></p> 
                        <p className='p-1 text-lg text-white font-bold'>Temps facturé : <span className='text-secondary-background'>{props.simulation.tps + " jours"}</span></p> 
                        <p className='p-1 text-lg text-white font-bold'>Taux d'imposition : <span className='text-secondary-background'>{props.simulation.tauxImpots}%</span></p> 
                </h3>
        </CardContent>
    )
}