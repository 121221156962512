import { Card, CardContent } from "@mui/material";
import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';
export default function ResultResultsMolecule(props) {
    return (
        <Card className='max-w-xs w-80 h-72 shadow-xl shadow-white sm:mt-10 md:mt-0  mb-5'>
                <CardContent>  
                    <p className='mt-2 p-1 justify-center flex rounded-3xl bg-primary-background text-white font-bold ' component="div">Résultats<SummarizeTwoToneIcon  fontSize='2'/></p> 
                    <p className='mt-2 p-1 text-lg text-primary-background'>Salaire Brut : ~{props.simulation.brut + "€"}</p>
                    <p className='p-1 text-lg text-primary-background'>Salaire Net : ~{props.simulation.netPayBeforeIT + "€"}</p>                                
                    <p className='p-1 text-lg text-primary-background'>Taux de restitution :{props.simulation.tauxRest}%</p>
                    <p className='p-1 text-lg text-primary-background font-bold'>Net Versé: <span className='p-1 bg-primary-background text-white rounded-2xl'>{props.simulation.totalPaid}€</span></p>
                </CardContent>
     </Card>
    )
}