import { HashRouter, Route, Routes } from 'react-router-dom'
import MainLayout from './layouts/MainLayout'
import Home from './pages/HomePage'
import SimulatorIdPage from './pages/SimulatorIdPage'
import { Provider } from "react-redux";
import { store } from "./store";
import './dist/output.css'
const AppRoutes = () => (

    <MainLayout>
      <Routes>
        <Route path='/' element={ <Home /> } />
        <Route path='simulator/:id' exact element={<SimulatorIdPage/> }/>
      </Routes>
    </MainLayout>
)

export default function App() {
  return (

    <Provider store={store}>
    <HashRouter>
      <Routes>
        <Route path='/*' element={ <AppRoutes /> } />
      </Routes>
    </HashRouter>
   </Provider>
   
  );
}
