import { useState } from "react";
import { Box,CardContent } from "@mui/material/";
import { useDispatch } from "react-redux";
import { updateSimulation } from "../store/actions/SimulationActions";
import  GetInfosModalAtom  from '../atoms/modalAtoms/GetInfosModalAtom'
import OptimisationSliderAtom from "../atoms/slidersAtoms/OptimisationSliderAtom";
import PeeSliderAtom from "../atoms/slidersAtoms/PeeSliderAtom"
import PerSliderAtom from "../atoms/slidersAtoms/PerSliderAtom";
import CeSliderAtom from "../atoms/slidersAtoms/CeSliderAtom"
export default function SlidersMolecule(props) {

  const dispatch = useDispatch();

  let simResult = props.simulation;
  
  const [formData, setFormData] = useState({});
  const [peeValue, setPeeValue] = useState(simResult.pee);
  const [ceValue, setCeValue] = useState(simResult.ce);
  const [perValue, setPerValue] = useState(simResult.per);
  const [globalValue, setGlobalValue] = useState(0)

  const handleChangeAll = (event, newValue) => {
    if (event.target.name === "pee") { setPeeValue(newValue) }
    else if (event.target.name === "per") { setPerValue(newValue) }
    else if (event.target.name === "ce") { setCeValue(newValue) }
    else if (event.target.name === "opti") {
      setGlobalValue(newValue)
      setPeeValue((simResult.maxPee * globalValue) / 100)
      setPerValue((simResult.maxPer * globalValue) / 100)
      setCeValue((simResult.maxCe * globalValue) / 100)
    }
    setFormData({ tjm: simResult.tjm,frais: simResult.frais,tps: simResult.tps,
      tauxImpots: simResult.tauxImpots,detailed: "false", pee: Number(peeValue), ce: Number(ceValue), per: Number(perValue), simulationID: localStorage.getItem('simulationID') })
  };

  const handleSubmit = async (event) => {
    await event.preventDefault();
    dispatch(updateSimulation(formData))};

  let SliderOnMainPage = true
  return (
    <Box>
        <CardContent>
              {SliderOnMainPage === true ? <>
                  <OptimisationSliderAtom handleSubmit={handleSubmit} globalValue={globalValue} handleChangeAll={handleChangeAll}    />
                  <div className="flex justify-center pt-3">
                         <GetInfosModalAtom simulation={props.simulation} />
                  </div>
              { simResult.gain !== 0 ?<p className="text-primary-background font-bold gold">Gain supplémentaire:   {simResult.gain}€</p> : ""}
               </>  : ( <>
                         <PeeSliderAtom handleSubmit={handleSubmit}  peeValue={peeValue} maxPee={simResult.maxPee} handleChangeAll={handleChangeAll} />
                         <PerSliderAtom handleSubmit={handleSubmit}  perValue={perValue} maxPer={simResult.maxPer} handleChangeAll={handleChangeAll} />
                         <CeSliderAtom  handleSubmit={handleSubmit}  ceValue={ceValue} maxCe={simResult.maxCe} handleChangeAll={handleChangeAll} />
              </>)}
          </CardContent>
     </Box>
  );
}
