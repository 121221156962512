import { useSelector } from 'react-redux';
import ResultResultsMolecule  from '../molecules/resultMolecules/ResultResultsMolecule';
import ResultParametersMolecule from '../molecules/resultMolecules/ResultParametersMolecule'; 
const Result = () => {
    const simulation = useSelector((state) => state.simulation.data);
    return (
                <div className='mt-10 md:mt-0'>
                    {simulation.netPayBeforeIT ?
                     <div>
                        <ResultResultsMolecule simulation={simulation} />
                        <div className='border-solid mb-5 border-2 border-white rounded-lg max-w-xs w-80 h-72 shadow-xl bg-primary-background'>
                            <ResultParametersMolecule simulation={simulation} />
                        </div>
                    </div>: ""} 
                </div>
    )
}
export default Result;
  