// import Simulator from '../components/simulator/Simulator'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
export default function SimulatorPage() {
    const { state } = useLocation()
    const navigate = useNavigate()
    console.log(state)
    useEffect(() => {
       if (!state) navigate('/')
    }, [navigate, state])
   return (
       <div className='App-header'>
           <p>id: { state.randomID } item:{ state.randomITEM }</p>
       </div>
   
   )
}