import { NavLink } from 'react-router-dom';
import { ReactComponent as LogoGw } from '../../../src/logo-GW-white.svg'

const Nav = (props) => {
    // const { title } = props
    return (
        <div className='flex justify-between items-center bg-transparent fixed w-100 text-white pt-4 pl-4 '>
            <NavLink to={'/'}>{<LogoGw height={20}  />}</NavLink>
            
        </div>
    )
}
export default Nav;