import {  combineReducers, applyMiddleware } from 'redux';
import { legacy_createStore as createStore} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { SimulationReducer } from './reducers/SimulationReducer';

const rootReducer = combineReducers({
      simulation: SimulationReducer
});

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))