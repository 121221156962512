import CalendarTodayTwoToneIcon from "@mui/icons-material/CalendarTodayTwoTone";
import { TextField } from "@mui/material/";
import { TimeFieldAtomTextField } from "../../molecules/StyleMolecule";
export default function TimeFieldAtom(props) {
    return (
        <div className="pr-4 pl-4 content-center justify-center items-center text-primary-background ">
                <label className="text-xl pr-2  font-bold" htmlFor="tps">Période (jours)</label>
                <TextField className="bg-white text-primary-background " type="number" name="tps" onChange={props.handleChange}
                           required id="tps" variant="standard" value={props.tps} sx={TimeFieldAtomTextField}/>
                <CalendarTodayTwoToneIcon className="text-primary-background" />
                <div className="flex justify-start content-start pb-5">
                             <p className="pt-1 pr-2"><button name="tps" value={19} type="button" onClick={props.handleChange} className="hover:bg-secondary-background bg-primary-background text-white font-bold rounded-3xl p-1 ">19 jours</button></p>
                              <p className="pt-1 pr-2"><button name="tps" value={20} type="button" onClick={props.handleChange} className="hover:bg-secondary-background bg-primary-background text-white font-bold rounded-3xl p-1 ">20 jours</button></p>
                </div>
        </div>
    )
}