import EuroTwoToneIcon from "@mui/icons-material/EuroTwoTone";
import { TextField } from "@mui/material"
export default function TjmFieldAtom(props) {
    return (
                <div className="content-center pr-4 justify-center items-center ">
                         <label className="text-xl pr-10 text-primary-background font-bold" htmlFor="tjm">Taux Journalier</label>
                         <TextField  name="tjm" type="number" onChange={props.handleChange} required
                                    id="tjm" className="font-extra-bold text-xl rounded text-primary-background" variant="standard" value={props.tjm}/>
                         <EuroTwoToneIcon className="text-primary-background"   />
                         <div className="flex pb-5">
                                    <p className="pt-1 pr-2"><button name="tjm" value={250} type="button" onClick={props.handleChange} className="hover:bg-secondary-background bg-primary-background font-bold rounded-3xl p-1 ">250€</button></p>
                                    <p className="pt-1 pr-2"><button name="tjm" value={500} type="button" onClick={props.handleChange} className="hover:bg-secondary-background bg-primary-background font-bold rounded-3xl p-1 ">500€</button></p>
                                    <p className="pt-1"><button name="tjm" value={800} type="button" onClick={props.handleChange} className="hover:bg-secondary-background bg-primary-background font-bold rounded-3xl p-1 ">800€</button></p>
                         </div>
                </div>

    )
   }
  
