import { ReactComponent as LogoGw } from "../../../src/logo-GW-white.svg";

export default function FormHeaderAtom() {
  return (
    <div className="pb-10 flex justify-center content-center">
      <LogoGw
        className="bg-primary-background mr-4 rounded-2xl p-2"
        height={30}
      />
      <p className="text-primary-background font-bold">
        Simulez vos revenus chez Gentelman Worker
      </p>
    </div>
  );
}
