import axios from "axios";
import { GET_SIMULATION_DATA} from "./ActionTypes";

export const getSimulation = (query) => {
  return async (dispatch) => {
    try {
      if (query === undefined || query.tjm === "0" || query.tps === "0") {
        dispatch({ type: GET_SIMULATION_DATA, payload: "" })
      } else {
      await axios
        .post(`${process.env.REACT_APP_GENTLEMANWORKER_API_ENDPOINT}calcPayslipFromGlobalAmount`, query)
        .then((response) => {
          localStorage.setItem("simulationID",response.data.simulationID)
          dispatch({ type: GET_SIMULATION_DATA, payload: response.data.results });
        })
        .catch((err) => {
          console.log(err);
        });
      }
    } catch (err) { return console.log(err) }
  };
};

export const updateSimulation = (query) => {
  return async (dispatch) => {
    try {
      if (query === undefined || query.tjm === "0" || query.tps === "0") {
        dispatch({ type: GET_SIMULATION_DATA, payload: "" })
      } else {
      await axios
        .post(`${process.env.REACT_APP_GENTLEMANWORKER_API_ENDPOINT}updatePayslip`, query)
        .then((response) => {
          dispatch({ type: GET_SIMULATION_DATA, payload: response.data });
        })
        .catch((err) => {
          console.log(err);
        });
      }
    } catch (err) { return console.log(err) }
  };
};



