import { Slider } from '@mui/material'
import { OptimisationSliderStyle } from '../../molecules/StyleMolecule'
export default function OptimisationSliderAtom(props) {

    const marksAll = [{ value: 0, label: '0%' },{ value: 50, label: '50%' }, { value: 100, label: 'max %' }]

    function valueLabelFormat(value) {
            const units = ['%']
            let unitIndex = 0
            let scaledValue = value
            return `${scaledValue} ${units[unitIndex]}`
    }
    return (
        <div>
             <p className="bg-white mt-2 p-1 rounded-3xl font-bold flex justify-center text-primary-background">Optimisation GW</p>
             <div className='flex justify-center pt-2'>
                    <Slider name="opti" valueLabelDisplay='auto' valueLabelFormat={valueLabelFormat} min={0} max={100}
                        marks={marksAll} value={props.globalValue} onChange={props.handleChangeAll}
                        onChangeCommitted={props.handleSubmit}
                        sx={OptimisationSliderStyle}/>
             </div>  
        </div>
       
    )
}