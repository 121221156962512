import FeesFieldAtom from '../atoms/formFieldsAtoms/FeesFieldAtom'
import TimeFieldAtom from '../atoms/formFieldsAtoms/TimeFieldAtoms'
import TjmFieldAtom from '../atoms/formFieldsAtoms/TjmFieldAtom'
import FormHeaderAtom from '../atoms/formFieldsAtoms/FormHeaderAtom'
import CheckBoxFieldAtom from '../atoms/formFieldsAtoms/CheckBoxFieldAtom'
import AlertsAtom from '../atoms/AlertsAtom'
export default function FormFieldsMolecule(props) {
    return (
        <div>
            <AlertsAtom  isLoading={props.isLoading} isEmpty={props.isEmpty} simulation={props.simulation} />
            <FormHeaderAtom />
            <div className="lg:flex md:cols-span-12">
                    <TjmFieldAtom handleChange={props.handleChange} tjm={props.tjm} />
                    <FeesFieldAtom handleChange={props.handleChange} frais={props.frais} />
                    <TimeFieldAtom handleChange={props.handleChange} tps={props.tps} />
                    <CheckBoxFieldAtom handleChange={props.handleChange} check={props.check} />
            </div>
           
        </div>
    )
 }