import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSimulation } from "../store/actions/SimulationActions";
import CalculateIcon from "@mui/icons-material/Calculate";
import SlidersMolecule from "../molecules/SlidersMolecule";
import FormFieldsMolecule from "../molecules/FormFieldsMolecule";
import MenuMolecule from "../molecules/MenuMolecule";
const Form = () => {

  const simulation = useSelector((state) => state.simulation.data);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({  tjm: "",  frais: "",  tps: "", tauxImpots: "",  ce: "",  pee: "",  per: "", detailed: "false" });
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState();

  const handleChange = (event) => {
    if (event.target.name === "checkbox") {setIsChecked(!isChecked)} 
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setIsEmpty(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (formData.tjm === "" || formData.tps === "") { setIsEmpty(true); } 
    else {
      dispatch(getSimulation(formData));
      setFormData({ tjm: "", frais: "", tps: "", ce: "", pee: "",  per: "",  tauxImpots: "11" });
      setIsLoading(true)
    }
  };
  const handleReset = () => {
    dispatch(getSimulation())
    setIsLoading(false)
  };
  return (
    <div>
      {!simulation.netPayBeforeIT ? 
        <div className="bg-white p-20 ml-5 mr-5 rounded flex">
                <form  noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <div>
                      <FormFieldsMolecule isEmpty={isEmpty} simulation={simulation} isLoading={isLoading} handleChange={handleChange} tjm={formData.tjm} tps={formData.tps} frais={formData.frais} tauxImpots={formData.tauxImpots} check={isChecked} /> 
                      <div className="flex justify-center">
                            <button className=" hover:bg-primary-background rounded p-2 flex  bg-secondary-background font-bold"  type="submit">Calculer Mes Revenu <CalculateIcon />
                            </button> 
                      </div>       
                    </div>
                 </form>             
        </div>
      : "" }
      {simulation.netPayBeforeIT ? 
        <div className="md:ml-5 ">
           <div className="mb-5   bg-primary-background max-w-xs w-80 h-72 shadow-xl border-2 border-solid border-white rounded-lg">       
                     <SlidersMolecule simulation={simulation} />
              </div>
                     <MenuMolecule  handleReset={handleReset} simulation={simulation} />
          </div> : "" }
  </div>);
};
export default Form;
