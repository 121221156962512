import { Slider } from "@mui/material";
import { styled } from "@mui/material";
import { TableCell, tableCellClasses,TableRow } from "@mui/material/";

export const PerSliderStyle =  { "& .MuiSlider-track":{color: "white"},height: 8,width: 200,"& .MuiSlider-markLabel":{color: "white"},
             "& .MuiSlider-valueLabel": {backgroundColor: "white", color:"rgb(250,16,105)"}}

export const PeeSliderStyle = { "& .MuiSlider-track":{color: "white"},height: 8,width: 200,"& .MuiSlider-markLabel":{color: "white"},
             "& .MuiSlider-valueLabel": {backgroundColor: "white", color:"rgb(250,16,105)"}}

export const OptimisationSliderStyle = { "& .MuiSlider-track":{color: "white"},height: 8,width: 200,"& .MuiSlider-markLabel":
             {color: "white", fontWeight: "bold"},"& .MuiSlider-valueLabel":{margin:3,fontWeight:"bold",backgroundColor:
             "white", color:"rgb(250,16,105)"},"& .MuiSlider-thumb":{color:"rgb(250,16,105)"}
                                        }
export const CeSliderStyle = { "& .MuiSlider-track":{color: "white"},height: 8,width: 200,"& .MuiSlider-markLabel":{color: "white"},
             "& .MuiSlider-valueLabel": {backgroundColor: "white", color:"rgb(250,16,105)"}}

export const PrettoSlider = styled(Slider)({height: 8, "& .MuiSlider-markLabel": {color: "white"},
             "& .MuiSlider-step": {color: "blue"},
             "& .MuiSlider-thumb": { backgroundColor: "white","&:focus, &:hover, &.Mui-active,&.Mui-focusVisible"
             : {boxShadow: "inherit",color: "white"},"&:before": {display: "none",},},
             "& .MuiSlider-valueLabel": { lineHeight: 1.2, color: "#52af77", fontSize: 12,
             background: "unset", padding: 20, width: 32, height: 32, borderRadius: "50% 50% 50% 0",
             transformOrigin: "bottom left",
             transform: "translate(50%, -100%) rotate(-45deg) scale(0)", "&:before": { display: "none" },
             "&.MuiSlider-valueLabelOpen": { transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
             color: "#52af77", fontWeight:"bold" }, "& > *": { transform: "rotate(45deg)"}}});

export const OptimisationSlider = styled(Slider)({height: 8,width:200,display:"flex",alignItems: "center",
             "& .MuiSlider-markLabel": {color: "rgb(255,255,255)",fontWeight: "bold"},
             "& .MuiSlider-step": {color: "white"},"& .MuiSlider-track":{color: "white"},
             "& .MuiSlider-thumb": {backgroundColor: "rgb(250,16,105)",
             "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {boxShadow: "inherit",color: "white"},
             "&:before": {display: "none",},},"& .MuiSlider-valueLabel":
             { lineHeight: 1.2, color: "#52af77", fontSize: 12,
             background: "rgb(255,255,255)", padding: 10, width: 16, height: 16, borderRadius: "50% 50% 50% 0",
             transformOrigin: "bottom left",transform: "translate(50%, -100%) rotate(-45deg) scale(0)", "&:before":
             { display: "none" },"&.MuiSlider-valueLabelOpen": { transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
             color: "rgb(250,16,105)", fontWeight:"bold" }, "& > *": { transform: "rotate(45deg)"}}});

export const SaveModalStyle = { color: "aliceblue", marginTop: 10,textAlign: "center",padding: 25};
export const saveSimulationTypography = { fontWeight: "bold", marginBottom: 2 }
export const saveSimulationTextField = {backgroundColor: "aliceblue", borderRadius: 1, textAlign: "center",marginRight:2, marginBottom: 3}

export const ApercuModalStyle = { modal: { width: "100%",display:"flex",justifyContent:"center", marginTop: 10 },
             modalTitle: { fontWeight: "bold", marginBottom: 2, color:"white", textAlign:'center' },
             maxHeight: { maxHeight: 400 },center: { display: "flex", justifyContent: "center" }}

export const modalInfosStyle = { position: "absolute", top: "50%", left: "50%", color: "aliceblue", transform: "translate(-50%, -50%)",
             width: 500, bgcolor: "rgb(16,23,96)", border: "2px solid #fff", borderRadius: "15px", boxShadow: 24, textAlign: "center",
             display: "flex", flexDirection: "column", p: 4,alignItems:"center", paddingBottom: 10}

export const AlertStyle = {marginTop: 2}

export const TimeFieldAtomTextField = { borderRadius: 1, justifyContent:"center" }
export const FeesFieldAtomStyle = { borderRadius: 1,justifyContent: "center" }
export const CheckBoxStyle = {color: "rgb(250,16,105)","&.Mui-label":{ color: "rgb(250,16,105)"},
             "&.Mui-checked": { color: "rgb(250,16,105)" },fontWeight: "900" }

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {backgroundColor: theme.palette.common.black,color: "#fff",fontWeight: "bold"},
  [`&.${tableCellClasses.body}`]: {fontSize: 14,fontWeight: "bold",textAlign: "start"},}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({"&:nth-of-type(odd)": {backgroundColor: theme.palette.action.hover,
             fontWeight: "bold",textAlign: "end"},"&:last-child td, &:last-child th": {border: 0,},}));