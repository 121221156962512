import SaveModalAtom from "../atoms/modalAtoms/SaveModalAtom"
import ApercuModalAtom from "../atoms/modalAtoms/ApercuModalAtom"
import { CardContent } from "@mui/material";
export default function MenuMolecule(props) {
    return (
        <div className="bg-white mb-5 rounded-xl max-w-xs w-80 h-72 shadow-xl">
        <CardContent>
                  <p className="bg-primary-background text-white  p-1 rounded-3xl font-bold justify-center flex">Menu</p>
                  <SaveModalAtom simulation={props.simulation} className="w-100" />
                  <ApercuModalAtom simulation={props.simulation} className="w-100"/>
                  <button className="hover:bg-secondary-background  border-2 border-secondary-background hover:text-white rounded  p-2 w-72 bg-white text-secondary-background  font-bold" onClick={props.handleReset} variant="filled">Nouvelle Simulation
                  </button>
        </CardContent>
   </div>
    )
}