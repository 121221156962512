import Form from '../organisms/FormOrganism'
import Result from '../organisms/ResultOrganism'

const Home = (props) => { 
    return (
            <div className='flex justify-center text-white items-center min-h-screen bg-primary-background'>
                    <div className='md:flex md:col-span-12 sm:mt-10 md:mt-0 '>
                          <Result />
                          <Form  />         
                    </div>
            </div>
         );
};
export default Home;
