import PercentTwoToneIcon from "@mui/icons-material/PercentTwoTone";
import { Typography, TextField,Checkbox, FormGroup, FormControlLabel } from "@mui/material"
import { CheckBoxStyle } from "../../molecules/StyleMolecule";
export default function CheckboxFieldAtom(props) {
    return (
                <div className="flex">
                        <FormGroup className='pt-6'> 
                            <FormControlLabel className="bold" control={
                            <Checkbox name="checkbox" sx={CheckBoxStyle}
                                        checked={props.check} onChange={props.handleChange}/>} label={<Typography className='text-primary-background'>Je connais mon taux d'imposition</Typography>}/>
                        </FormGroup>
                        {props.check === true ?
                                 <div className="flex pt-7"> <TextField className="bg-white text-primary-background"  type="number"  name="tauxImpots"  onChange={props.handleChange}
                                      required id="tauxImpots"  value={props.tauxImpots} variant="standard" />
                                                <PercentTwoToneIcon className="text-primary-background" />
                                 </div>
                        :  ""}
                </div>
    )
}
 